.Group__Id {
  width: 100%;
  object-fit: fill;
  &__Image {
    height: 370px;
    width: 100%;
  }
  &__Container {
    padding: 10px 20px;
    &__Name {
      text-align: start;
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #000000;
    }
    &__C {
      display: flex;
      justify-content: space-between;
      text-align: start;
      align-items: center;
    }
    &__El {
      margin-top: 20px;
      text-align: start;
      &__C {
        // display: flex;
        margin-right: 30px;
        color: #313131;
        font-weight: 500;
        &__Active {
          width: 100%;
        }
      }
      &__Active {
        color: #f87e0a;
        border-bottom: 1px solid #f87e0a;

      }
    }
    &__Map {
      display: flex;
    }
  }
}
